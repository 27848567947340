/*
Template Name: Veltrix - Admin & Dashboard Template
Author: Themesbrand
Version: 4.0.0
Website: https://themesbrand.com/
Contact: themesbrand@gmail.com
File: Main Css File
*/

//Fonts
@import "custom/fonts/fonts";

//Core files
@import "./node_modules/bootstrap/scss/functions";
@import "./node_modules/bootstrap/scss/variables";
@import "variables";
@import "./node_modules/bootstrap/scss/mixins.scss";

// Structure
@import "custom/structure/general";
@import "custom/structure/topbar";
@import "custom/structure/page-head";
@import "custom/structure/page-title";
@import "custom/structure/footer";
@import "custom/structure/right-sidebar";
@import "custom/structure/vertical";
@import "custom/structure/horizontal-nav";
@import "custom/structure/layouts";

// Components
@import "custom/components//waves";
@import "custom/components/avatar";
@import "custom/components/alert";
@import "custom/components/helper";
@import "custom/components/widgets";
@import "custom/components/demos";
@import "custom/components/print";


// Plugins
@import "custom/plugins/bootstrap-touchspin";
@import "custom/plugins/custom-scrollbar";
@import "custom/plugins/calendar";
@import "custom/plugins/session-timeout";
@import "custom/plugins/range-slider";
@import "custom/plugins/sweatalert2";
@import "custom/plugins/rating";
@import "custom/plugins/parsley";
@import "custom/plugins/color-picker";
@import "custom/plugins/select2";
@import "custom/plugins/switch";
@import "custom/plugins/datepicker";
@import "custom/plugins/datatable";
@import "custom/plugins/table-editable";
@import "custom/plugins/form-editors";
@import "custom/plugins/form-upload";
@import "custom/plugins/form-wizard";
@import "custom/plugins/responsive-table";
@import "custom/plugins/x-editable";
@import "custom/plugins/morris";
@import "custom/plugins/chartist";
@import "custom/plugins/apexcharts";
@import "custom/plugins/flot";
@import "custom/plugins/sparkline-chart";
@import "custom/plugins/google-map";

// Pages
@import "custom/pages/authentication";
@import "custom/pages/email";
@import "custom/pages/coming-soon";
@import "custom/pages/timeline";
@import "custom/pages/extras-pages";
@import "custom/pages/gallery";

  
  #toast-container {
    margin-top: 1rem;
  }
  
  #toast-container.toast-top-full-width>.toast {
    max-width: 570px;
  }
  
  #toast-container>.toast-success {
    background-color: #07b664;
    opacity:1;
    box-shadow:0px 0px 10px rgba(0,0,0,0.2);
  }
  
  #toast-container>.toast-success:hover {
    background-color: #07b664;
    opacity:1;
    box-shadow:0px 0px 10px rgba(0,0,0,0.4);
  }

  #toast-container>.toast-error {
    background-color: #ce1c1c;
    opacity:1;
    box-shadow:0px 0px 10px rgba(0,0,0,0.2);
  }
  
  #toast-container>.toast-error:hover {
    background-color: #ce1c1c;
    opacity:1;
    box-shadow:0px 0px 10px rgba(0,0,0,0.4);
  }

  .head-text{
    font-size: 20px;
    font-weight: 600;
    padding: 5px;
    // border-bottom: 1px solid #eef;
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .vertical-collpsed .navbar-header .navbar-brand-box .logo{
    visibility: hidden;
  }

  .ml-4{
    margin-left: 5px;
  }

  .plan-name{
    font-size: 24px;
    font-weight: 600;
  }

  .formbold-mb-5 {
    margin-bottom: 20px;
  }
  .formbold-pt-3 {
    padding-top: 12px;
  }
  .formbold-main-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 48px;
  }

  .formbold-form-wrapper {
    margin: 0 auto;
    max-width: 550px;
    width: 100%;
    background: white;
  }
  .formbold-form-label {
    display: block;
    font-weight: 500;
    font-size: 16px;
    color: #07074d;
    margin-bottom: 12px;
  }
  .formbold-form-label-2 {
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 20px;
  }

  .formbold-form-input {
    width: 100%;
    padding: 12px 24px;
    border-radius: 6px;
    border: 1px solid #e0e0e0;
    background: white;
    font-weight: 500;
    font-size: 16px;
    color: #6b7280;
    outline: none;
    resize: none;
  }
  .formbold-form-input:focus {
    border-color: #6a64f1;
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.05);
  }

  .formbold-btn {
    text-align: center;
    font-size: 16px;
    border-radius: 6px;
    padding: 14px 32px;
    border: none;
    font-weight: 600;
    background-color: #6a64f1;
    color: white;
    cursor: pointer;
  }
  .formbold-btn:hover {
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.05);
  }

  .formbold--mx-3 {
    margin-left: -12px;
    margin-right: -12px;
  }
  .formbold-px-3 {
    padding-left: 12px;
    padding-right: 12px;
  }
  .flex {
    display: flex;
  }
  .flex-wrap {
    flex-wrap: wrap;
  }
  .w-full {
    width: 100%;
  }

  .formbold-file-input input {
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .formbold-file-input label {
    position: relative;
    border: 1px dashed #e0e0e0;
    border-radius: 6px;
    min-height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 48px;
    text-align: center;
  }
  .formbold-drop-file {
    display: block;
    font-weight: 600;
    color: #07074d;
    font-size: 20px;
    margin-bottom: 8px;
  }

  .formbold-or {
    font-weight: 500;
    font-size: 16px;
    color: #6b7280;
    display: block;
    margin-bottom: 8px;
  }
  .formbold-browse {
    font-weight: 500;
    font-size: 16px;
    color: #07074d;
    display: inline-block;
    padding: 8px 28px;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
  }

  .formbold-file-list {
    border-radius: 6px;
    background: #f5f7fb;
    padding: 16px 32px;
  }

  .formbold-file-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .formbold-file-item button {
    color: #07074d;
    border: none;
    background: transparent;
    cursor: pointer;
  }

  .formbold-file-name {
    font-weight: 500;
    font-size: 16px;
    color: #07074d;
    padding-right: 12px;
  }
  .formbold-progress-bar {
    margin-top: 20px;
    position: relative;
    width: 100%;
    height: 6px;
    border-radius: 8px;
    background: #e2e5ef;
  }

  .formbold-progress {
    position: absolute;
    width: 75%;
    height: 100%;
    left: 0;
    top: 0;
    background: #6a64f1;
    border-radius: 8px;
  }
  table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }
  
  td, th {
    border: 1px solid #dddddd;
    text-align: center;
    padding: 8px;

  }
  
  tr:nth-child(even) {
    background-color: #dddddd;
  }

  @media (min-width: 540px) {
    .sm\:w-half {
      width: 50%;
    }
  }

  .img-fluid.uploads{
    width: 100%;
    height: 130px;
  }

  .other-container{
    padding: 5px 0px;
    background: #333547;
    color: #fff;
  }

  .other-container-list{
    padding: 5px 0px;
  }

  .view-plan-container{
    display: flex;
    justify-content: space-between;
  }

  .margin-member{
    margin-left: 10px;
  }

  .row-container-view{
    border: 1px solid #eef0ff;
    padding: 5px 0px;
  }

  .view-form-content{
    font-size: 15px;
    padding-left: 5px;
  }

  .info-container{
    display: flex;
  }

  .not-found-message{
    font-size: 21px;
    text-align: center;
    padding: 10px;
  }

  .receipt-wrapper{
    padding: 15px;
    border: 1px solid #eef;
  }

  .receipt-pdf {
    padding: 5px;
    margin: 5px;
    display: flex;
    justify-content: right;
}


.address-invoice-wrapper{
  display: flex;
  justify-content: center;
  .address-invoice {
    padding: 5px;
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}
}

.invoice-footer-wrapper{
  margin-top:45px;
  margin-bottom: 10px;
  border-top: 1px solid #eef;
  border-bottom: 1px solid #eef;
  display: flex;
  justify-content: center;
  .invoice-footer {
    padding: 5px;
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}
}

.table-contribution{
  margin-top:10px;
  margin-bottom: 10px;
}

.receipt-text-container{
  margin-top:15px;
  display: flex;
  justify-content: center;
  .receipt-text {
    padding: 5px;
   font-size: 25px;
   text-decoration: underline;
   font-weight: 700;
}
}


.info-invoice{
  margin-top:35px;
  margin-bottom:15px;
  .invoice-receipt{
    font-size: 15px;
  }
}
.border-bootom-invoice{
  border-bottom: 1px solid #eef;
}

.space-even{
  justify-content: space-evenly;
}
.text-capitalize{
  text-transform: capitalize
}

.margin-btn{
  margin-right:5px;
}
  /* Define css class for global loading message to cover 
   screen during axios operations */

.loading-indicator:before {
  content: '';
  background: #e9ecef;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
}

.loading-indicator:after {
  content: 'Loading';
  position: fixed;
  width: 100%;
  top: 50%;
  left: 0;
  z-index: 1001;
  color:#858181;
  text-align:center;
  font-weight:bold;
  font-size:1.5rem;        
}

.bg-user-login {
  background: #ffd864 !important;
}

.user-login-tab-container{
  display: flex;
  width: 100%;
  list-style: none;
  text-align: center;
  padding-left: 0;
  margin-bottom:0;
}

.user-login-tab{
  ul{
    li.nav-item{
      width: 50%;
      padding:15px; 
      padding: 15px;
      font-size: 15px;
      font-weight: 500;
      cursor: pointer;
      background: #ffda6db8;
    }
    li.nav-item.current {
      text-align:center;
      background: #ffd864;
    }
  }
}

.content-flex-right{
  justify-content: right;
}

.form-control:disabled {
  background-color: #dfdfdf;
  opacity: 1;
}

.cursor-pointer{
  cursor: pointer;
}

.print-btn{
  margin: 15px auto;
  display: flex;
}
  
.uploads-member{
  height: 200px;
  width: 100%;
  object-fit: cover;
}

.counter{
  text-align: center;
  background: #ffffff !important;
  color: #000000;

}

.counter-count
{
  font-size: 50px;
  font-weight: bold;
  position: relative;
  color: #ffffff;
  text-align: center;
  display: inline-block;
}

.table-bordered th, .table-bordered td{
  text-align:center;
}
.form-control{
  text-transform: capitalize;
  appearance: auto;

}
.form-control.not-add{
  text-transform: none;
}
.pancard-no{
  text-transform: uppercase;
}

.mangpatra-flex{
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #c0c0c0;
    padding-bottom: 7px;
  .font-size-val{
    font-size: 21px;
  }
}

.form-right{
  text-align: right;
}